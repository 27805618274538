<template>
  <div class="jobfair">
    <div class="job-content">
      <div class="banner">
        <div class="banner-img"></div>
      </div>
      <div class="home-select">
        <div class="select-contain">
          <el-form>
            <el-form-item>
              <el-col :span="20">
                <el-input
                  v-model="selfrom.KW"
                  placeholder="搜索职位、公司"
                ></el-input>
              </el-col>
              <el-col :span="4">
                <el-button
                  type="primary"
                  @click="
                    selfrom.pageNumber = 1;
                    GetList();
                  "
                  >搜索</el-button
                >
              </el-col>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="jobfair-activity">
        <div class="activity-contain">
          <div class="title">校园招聘会</div>
          <div class="activity-cell">
            <div
              class="activity-item"
              v-for="item in activitydata"
              :key="item.id"
              @click="goto('/jobfairdetail', { id: item.id, school: true })"
            >
              <div class="activity-img">
                <img :src="setcompanylogo(item.cZbCover)" alt="" />
              </div>
              <div class="activity-body">
                <div class="activity-name">
                  <b>【{{ item.cZhuti }}】</b>{{ item.czph }}
                </div>
                <div class="activity-state">
                  <span class="label">{{ zphtype(item.cDaxing) }}</span>
                  <span class="gyx" v-if="item.bIsZbzp">直播</span>
                  <span class="label" v-if="item.cZphlb == '公益性'"
                    >公益性</span
                  >
                </div>
                <div class="activity-data">
                  活动时间：{{ stringToDates(item.cZphDate) }}
                </div>
                <div class="activity-address">
                  活动地址：{{ item.cAddress }}
                </div>
              </div>
              <!-- <div class="activity-btn">
                <el-button type="primary">查看详情</el-button>
              </div> -->
            </div>
            <el-empty
              v-if="activitydata.length < 1"
              :image-size="200"
            ></el-empty>
          </div>
          <div style="text-align: center; margin-top: 10px">
            <el-pagination
              hide-on-single-page
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="selfrom.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="selfrom.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="home-position">
        <div class="position-content">
          <div class="position-title">最新招聘</div>
          <el-empty v-if="positiondata.length < 1" :image-size="200"></el-empty>
          <div class="position-cell">
            <div
              class="position-item"
              v-for="item in positiondata"
              :key="item.oF_ID"
              @click="goto('/jobdetail', { id: item.oF_ID })"
            >
              <div class="position-hd">
                <div class="post-name">{{ item.oF_POSI_NAME }}</div>
                <div class="post-salary">
                  {{ setsalary(item.oF_SALARY, item.oF_SALARYE) }}
                </div>
              </div>
              <div class="position-bd">
                <span>{{ item.oF_WORKAREAName }}</span>
                <!-- <div class="desc">{{ item.oF_POSI_DESC }}</div> -->
                <span>{{
                  item.oF_EDU_REQ === null ? "无" : item.oF_EDU_REQ
                }}</span
                ><span>{{ item.oF_POSI_NATURE }}</span>
                <span>{{ item.oF_INVITE_NUM }} 人</span>
              </div>
              <div class="position-card">
                <div class="card-img">
                  <img :src="setcompanylogo(item.coM_LOGO, 'logos')" alt="" />
                </div>
                <div class="card-body">
                  <div class="card-name">{{ item.coM_NAME }}</div>
                  <div class="card-posthot">
                    <b>{{ item.counts }}</b
                    >个热招岗位
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="position-more" @click="goto('/jobhunting', {}, 2)">
            <router-link to="">更多岗位</router-link>
          </div>
        </div>
      </div>
      <div class="jobfair-scene" v-if="false">
        <div class="scene-content">
          <div class="title">往期活动现场</div>
          <div class="scene-cell">
            <div class="scene-item" v-for="(item, j) in scenedata" :key="j">
              <div class="scene-img"><img :src="item.src" alt="" /></div>
              <div class="scene-title">【{{ item.type }}】{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Getjobfairlist, FreshOfferList } from "@/api/home";
import utils from "@/assets/js/utils";
import { isnull } from "@/assets/js/index";
import global from "@/assets/js/globalconfig";
export default {
  data() {
    return {
      defimgurl: global.baseimgurl,
      selfrom: {
        KW: "",
        pageNumber: 1,
        pageSize: 10,
        types: 1,
      },
      input: "",
      user: {},
      val: "",
      total: 0,
      activitydata: [],
      positiondata: [],
      isActive: false,
      scenedata: [
        {
          src: require("@/assets/img/sc1.jpg"),
          title: "促进高校毕业生就业大型公益活动",
          type: "市县联动",
        },
        {
          src: require("@/assets/img/sc1.jpg"),
          title: "促进高校毕业生就业大型公益活动",
          type: "市县联动",
        },
        {
          src: require("@/assets/img/sc1.jpg"),
          title: "促进高校毕业生就业大型公益活动",
          type: "市县联动",
        },
      ],
    };
  },
  created() {
    if (!isnull(localStorage.getItem("userinfo"))) {
      this.user = JSON.parse(localStorage.getItem("userinfo"));
    }

    this.GetList();
    this.FreshOfferList();
  },
  methods: {
    setcompanylogo(url) {
      return isnull(url)
        ? require("@/assets/img/ad1.png")
        : this.defimgurl + url;
    },
    goto(url, data, type = 1) {
      this.resolvewin(url, data, type);
    },
    GetList() {
      var _this = this;
      _this.selfrom.showLoadType = ".activity-cell";
      Getjobfairlist(_this.selfrom).then((res) => {
        if (res.success) {
          _this.activitydata = res.data.rows;
          _this.total = res.data.total;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    FreshOfferList() {
      var _this = this;

      FreshOfferList().then((res) => {
        if (res.success) {
          _this.positiondata = res.data.rows;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    stringToDates(data) {
      return utils.stringToDate(data);
    },
    enter(cell) {
      this.isActive = true;
      this.num = cell.id;
    },
    leave() {
      this.isActive = false;
    },
    handleSizeChange(val) {
      this.selfrom.pageNumber = val;
      this.selfrom.pageNumber = 1;
      this.GetList();
    },
    handleCurrentChange(val) {
      this.selfrom.pageNumber = val;
      this.GetList();
    },
    setsalary(a, b) {
      var data = [];
      if (!isnull(a)) {
        if (a < 100 && a > 0) {
          data.push(a + "k");
        } else if (a >= 1000) {
          data.push(a / 1000 + "k");
        } else {
          data.push(a);
        }
      }
      if (!isnull(b)) {
        if (b < 100 && b > 0) {
          data.push(b + "k");
        } else if (b >= 1000) {
          data.push(b / 1000 + "k");
        } else {
          data.push(b);
        }
      }
      if (data.length === 0) {
        return "面议";
      } else {
        return data.join("-");
      }
    },
    zphtype(i) {
      switch (i) {
        case 0:
          return "普通招聘会";
          break;
        case 1:
          return "大型招聘会";
          break;
        case 2:
          return "赴外招聘会";
          break;
        case 3:
          return "直播招聘会";
          break;
        case 99:
          return "专场招聘会";
          break;
        default:
          break;
      }
    },
  },
  computed: {
    listTemp: function () {
      var companydata = this.companydata;
      var arrTemp = [];
      var index = 0;
      var sectionCount = 8;
      for (var i = 0; i < companydata.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(companydata[i]);
      }
      return arrTemp;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 300px;
  padding: 0;
  margin: 0px;
  margin-bottom: 20px;
  position: relative;
  background: url(../../assets/img/school.png) top center no-repeat;
  .banner-img {
    width: 1200px;
    height: 300px;
    margin: 0px auto;
    padding: 0;
    background: url(../../assets/img/school.png) top center no-repeat;
  }
}
.home-select {
  width: 960px;
  margin: 0px auto;
  padding: 24px;
  .select-contain {
    .el-form {
      width: 100%;
      background: #fff;
      .el-select ::v-deep {
        .el-input {
          padding-top: 4px;
          padding-bottom: 4px;
          .el-input__inner {
            border: none;
            border-radius: 0;
            font-size: 14px;
          }
          .el-input__inner::placeholder {
            color: #333;
          }
        }
      }
      .el-input ::v-deep {
        padding-top: 4px;
        padding-bottom: 4px;
        .el-input__inner {
          border: none;
          border-radius: 0;
          border-left: 1px solid #eee;
        }
      }
      .el-button {
        width: 100%;
        text-align: center;
        border-radius: 0;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 16px;
      }
    }
    .hot-word {
      text-align: left;
      font-size: 16px;
      color: #787878;
      a {
        padding: 0px 12px;
        color: #409eff;
        text-decoration: none;
      }
    }
  }
}
.jobfair-activity {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  .activity-contain {
    .title {
      font-size: 18px;

      color: #121d3b;

      margin-bottom: 16px;
    }
    .activity-cell {
      .activity-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 15px 10px;
        margin: 0 10px;
        border-top: 1px solid #eee;
        cursor: pointer;
        .activity-img {
          width: 200px;
          height: 110px;
          border-radius: 8px;
          display: flex;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
          }
        }
        .activity-body {
          flex: 1;
          width: 100%;
          text-align: left;
          padding-left: 20px;
          position: relative;
          .activity-name {
            color: #000;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
            b {
              color: #ff5722;
            }
          }
          .activity-state {
            margin-bottom: 10px;
          }
          .activity-data {
            font-size: 14px;
            color: #787878;
            margin-bottom: 12px;
          }
          .activity-address {
            font-size: 14px;
            color: #787878;
          }
        }
        .activity-btn {
          position: absolute;
          bottom: 16px;
          right: 0;
        }
      }
      .activity-item:first-child {
        border-top: none;
      }
      .activity-item:hover {
        box-shadow: 0px 0px 5px rgba(64, 160, 255, 0.322);
        border-radius: 8px;
      }
    }
  }
}
.jobfair-company {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  .company-content {
    .company-title {
      font-size: 18px;
      color: #333;
      margin-bottom: 16px;
    }
    .company-cell {
      .el-carousel ::v-deep {
        .el-carousel__container {
          .el-carousel__item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .company-item {
              width: 275px;
              padding: 12px 0;
              background: #f2f2f2;
              margin-bottom: 15px;
              position: relative;
              overflow: hidden;
              .company-logo {
                height: 50px;
                text-align: center;
                img {
                  height: 50px;
                }
              }
              .company-name {
                font-size: 14px;
                line-height: 32px;
                color: #333;
                text-align: center;
              }
              .company-num {
                padding: 8px 0px;
                border-radius: 2px;
                border: 1px solid #eee;
                font-size: 12px;
                background: #fff;
                width: 180px;
                margin: 0px auto;
                text-align: center;
                b {
                  color: #ff0000;
                }
              }
              .company-post {
                position: absolute;
                padding: 16px;
                left: 0;
                right: 0;
                top: 140px;
                height: 110px;
                background: rgba($color: #000000, $alpha: 0.6);
                transition: all 0.5s;
                .post-item {
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  margin-bottom: 10px;
                  .post-name {
                    color: #fff;
                    font-size: 12px;
                  }
                  .post-salary {
                    font-size: 12px;
                    color: #ff0000;
                  }
                }
                .post-more {
                  text-align: center;
                }
              }
              .hoveractive {
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}
.jobfair-position {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  .position-content {
    .position-title {
      font-size: 18px;
      color: #000;
      margin-bottom: 16px;
    }
    .position-cell {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      .position-item {
        width: 243px;
        padding: 12px 16px;
        position: relative;
        background: #f2f2f2;
        margin-bottom: 16px;
        .position-hd {
          font-size: 16px;
          color: #409eff;
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;
          align-items: center;
          .post-salary {
            font-size: 16px;
            color: #ff6600;
            font-weight: bold;
          }
        }
        .position-bd {
          font-size: 12px;
          color: #787878;
          margin-bottom: 12px;
          text-align: left;
          span {
            padding: 0 4px;
            border-right: 1px solid #eee;
          }
          span:first-child {
            padding-left: 0;
          }
          span:last-child {
            border: none;
          }
        }
        .position-card {
          display: flex;
          align-items: center;
          text-align: left;
          padding-top: 12px;
          border-top: 1px dashed #ddd;
          .card-img {
            width: 32px;
            line-height: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            margin-right: 12px;
            background: #fff;
            border-radius: 2px;
            img {
              width: 100%;
            }
          }
          .card-body {
            flex: 1;
            .card-name {
              font-size: 14px;
              color: #333;
            }
            .card-posthot {
              font-size: 12px;
              color: #787878;
              b {
                color: #409eff;
              }
            }
          }
        }
      }
    }
    .position-more {
      width: 200px;
      height: 32px;
      line-height: 32px;
      color: #fff;
      font-size: 14px;
      margin: 0px auto;
      text-align: center;
      a {
        text-decoration: none;
        color: #fff;
        background: #409eff;
        transition: all 0.5s;
        display: block;
      }
      a:hover {
        background: rgba($color: #409eff, $alpha: 0.7);
      }
    }
  }
}
.jobfair-scene {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  .scene-content {
    .title {
      font-size: 18px;
      color: #000;
      margin-bottom: 16px;
    }
    .scene-cell {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      .scene-item {
        width: 375px;
        height: 210px;
        position: relative;
        z-index: 1;
        .scene-img {
          width: 375px;
          height: 210px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            transition: all 0.3s;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
        .scene-title {
          position: absolute;
          padding: 8px 16px;
          background: rgba($color: #000000, $alpha: 0.5);
          font-size: 14px;
          color: #fff;
          left: 0;
          right: 0;
          bottom: 0;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          -webkit-text-overflow: ellipsis;
          -moz-text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
.home-position {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  .position-content {
    .position-title {
      font-size: 18px;

      margin-bottom: 16px;
    }
    .position-cell {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      .position-item {
        width: 243px;
        padding: 12px 16px;
        position: relative;
        background: #f2f2f2;
        margin-bottom: 16px;
        cursor: pointer;
        .position-hd {
          font-size: 16px;
          color: #409eff;
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;
          align-items: center;
          .post-name {
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .post-salary {
            max-width: 70px;
            text-align: right;
            font-size: 16px;
            color: #ff6600;
            font-weight: bold;
          }
        }
        .position-bd {
          font-size: 12px;
          color: #787878;
          margin-bottom: 12px;
          text-align: left;
          .desc {
            height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }
          span {
            padding: 0 4px;
            border-right: 1px solid #eee;
          }
          span:first-child {
            padding-left: 0;
          }
          span:last-child {
            border: none;
          }
        }
        .position-card {
          display: flex;
          align-items: center;
          text-align: left;
          padding-top: 12px;
          border-top: 1px dashed #ddd;
          .card-img {
            width: 32px;
            line-height: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            margin-right: 12px;
            background: #fff;
            border-radius: 2px;
            img {
              width: 100%;
            }
          }
          .card-body {
            flex: 1;
            .card-name {
              font-size: 14px;
              word-break: keep-all;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 169px;
              white-space: normal;
              color: #333;
            }
            .card-posthot {
              font-size: 12px;
              color: #787878;
              b {
                color: #409eff;
              }
            }
          }
        }
      }
    }
    .position-more {
      width: 200px;
      height: 32px;
      line-height: 32px;
      color: #fff;
      font-size: 14px;
      margin: 0px auto;
      text-align: center;
      a {
        text-decoration: none;
        color: #fff;
        background: #409eff;
        transition: all 0.5s;
        display: block;
      }
      a:hover {
        background: rgba($color: #409eff, $alpha: 0.7);
      }
    }
  }
}
.gyx {
  padding: 4px 10px;
  border-radius: 4px;
  background: #ff7630;
  color: white;
  font-size: 14px;
  margin-right: 10px;
}
.label {
  padding: 4px 10px;
  border-radius: 4px;
  background: #409eff;
  color: white;
  font-size: 14px;
  margin-right: 10px;
}
</style>